import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "status": "published",
  "path": "/pi-hole-setup/",
  "heroImage": "pi_hole_logo.jpg",
  "title": "Installing PI Hole on my Home Server",
  "description": "Ad Blocker",
  "date": "2022-11-20T00:00:00.000Z",
  "tags": ["home", "code"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Are you tired of ads following you everywhere you go on the internet? Do you feel like you're being stalked by banner ads and pop-ups at every turn? Fear not, my friend - Pi Hole is here to save the day!`}</p>
    <p>{`In this blog post, I want to share with you my experience of installing Pi Hole on my home server. Pi Hole is like a bouncer for the internet - it blocks requests to ad-serving domains before they can even get to your device. And the best part? It's so effective, it'll make your internet browsing experience smoother than a freshly shaved dolphin.`}</p>
    <p>{`Setting up Pi Hole on your home server might sound daunting, but don't worry - you don't need a degree in rocket science to do it. In this post, I'll guide you through the installation process step by step, and provide some helpful tips to ensure you get the most out of your ad-free browsing experience.`}</p>
    <p>{`So, if you're ready to say goodbye to pesky ads and hello to a smoother internet experience, let's dive into the wonderful world of Pi Hole!`}</p>
    <h2>{`Setup Pi-hole on Proxmox`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Download ubuntu-20.04-standard_20.04-1_amd64.tar.gz`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Create CT`}</code></p>
        <ul parentName="li">
          <li parentName="ul">
            <h4 parentName="li">{`General`}</h4>
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`Node:`}</strong>{` proxmox`}</li>
              <li parentName="ul"><strong parentName="li">{`VM ID:`}</strong>{` 150`}</li>
              <li parentName="ul"><strong parentName="li">{`Name:`}</strong>{` Pi-Hole`}</li>
              <li parentName="ul"><strong parentName="li">{`Unprivileged container:`}</strong>{` checked`}</li>
              <li parentName="ul">{`Set Password`}</li>
            </ul>
          </li>
          <li parentName="ul">
            <h4 parentName="li">{`Template`}</h4>
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`Storage:`}</strong>{` local`}</li>
              <li parentName="ul"><strong parentName="li">{`Template:`}</strong>{` ubuntu-20.04-standard_20.04-1_amd64.tar.gz`}</li>
            </ul>
          </li>
          <li parentName="ul">
            <h4 parentName="li">{`Disks`}</h4>
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`Disk size (GB):`}</strong>{` 8`}</li>
              <li parentName="ul">{`Click `}<strong parentName="li">{`Next:`}</strong></li>
            </ul>
          </li>
          <li parentName="ul">
            <h4 parentName="li">{`CPU`}</h4>
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`Cores:`}</strong>{` 1`}</li>
            </ul>
          </li>
          <li parentName="ul">
            <h4 parentName="li">{`Menory`}</h4>
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`Memory (MiB):`}</strong>{` 512`}</li>
              <li parentName="ul"><strong parentName="li">{`Swap (MiB):`}</strong>{` 512`}</li>
            </ul>
          </li>
          <li parentName="ul">
            <h4 parentName="li">{`Network`}</h4>
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`IPV4:`}</strong>{` 192.168.68.150/24`}</li>
              <li parentName="ul"><strong parentName="li">{`Gateway:`}</strong>{` 192.168.68.1`}</li>
            </ul>
          </li>
          <li parentName="ul">
            <h4 parentName="li">{`DNS`}</h4>
            <ul parentName="li">
              <li parentName="ul">{`Click `}<strong parentName="li">{`Next`}</strong></li>
            </ul>
          </li>
          <li parentName="ul">
            <h4 parentName="li">{`Confirm`}</h4>
            <ul parentName="li">
              <li parentName="ul">{`Click `}<strong parentName="li">{`Start after created`}</strong></li>
              <li parentName="ul">{`Click `}<strong parentName="li">{`Finish:`}</strong></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Navigate to: `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`150 (Pi-Hole)`}</code>{` > `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Console`}</code></p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Username:`}</strong>{` root`}</li>
          <li parentName="ul"><strong parentName="li">{`Password:`}</strong>{` `}{`*`}<strong parentName="li">{`*`}{`*`}{`*`}{`*`}{`*`}</strong></li>
        </ul>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}><span parentName="code" {...{
                "className": "token function"
              }}>{`apt`}</span>{` update
`}</code></pre></div>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}><span parentName="code" {...{
                "className": "token function"
              }}>{`apt`}</span>{` upgrade -y`}</code></pre></div>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}><span parentName="code" {...{
                "className": "token function"
              }}>{`apt`}</span>{` `}<span parentName="code" {...{
                "className": "token function"
              }}>{`install`}</span>{` `}<span parentName="code" {...{
                "className": "token function"
              }}>{`curl`}</span></code></pre></div>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}><span parentName="code" {...{
                "className": "token function"
              }}>{`curl`}</span>{` -sSL https://install.pi-hole.net `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`|`}</span>{` `}<span parentName="code" {...{
                "className": "token function"
              }}>{`bash`}</span></code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Okay everything through setup`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Change password`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}>{`pihole -a -p`}</code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Navigate to:: `}<a parentName="p" {...{
            "href": "http://192.168.68.150/admin",
            "target": "_blank",
            "rel": "nofollow"
          }}>{`http://192.168.68.150/admin`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Login to Pi-hole`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Navigate to: Tools > Update Gravity`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Click `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`Update`}</code></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Change Primary DNS Server on router:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Log into App on phone`}</li>
          <li parentName="ul">{`Navigate to: Advanced > DHCP Server`}</li>
          <li parentName="ul">{`Change Primary DNS to: `}<strong parentName="li">{`192.168.68.150`}</strong></li>
        </ul>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      